import posthog from 'posthog-js'

export default {
    install(app) {
        app.config.globalProperties.$posthog = posthog.init(
            'phc_dLQWqdIsTMdGqCtUK3JZ5UEUtEr0wYBKm0uTqbmyI6L',
            {
                api_host: 'https://eu.i.posthog.com',
                session_recording: {
                    maskAllInputs: true, // Masks all input elements
                    maskTextSelector: '*', // Masks all text elements
                },
                enable_heatmaps: true,
            }
        )
    },
}
